.home_welcome_wrap {
    /* padding: 20px; */
    width: 100%;
}

.home_section1,
.home_section3 {
    display: flex;
    background: #FFFEFD;

    width: 100%;
    /* gap: 100px; */
    justify-content: space-between;
    /* height: 100%; */

    border-radius: 0px 0px 20px 20px;
    background: #FFFEFD;

    box-shadow: 0px 4px 9.3px 0px rgba(0, 0, 0, 0.06);

}

.home_section1-left-top {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.home_section1-left {
    display: flex;
    flex-direction: column;
    padding: 25px;
    /* gap: 20px; */
    justify-content: space-between;
    margin-bottom: '20px';
}

.home_section1-left-header {
    /* margin-top: 2.25rem; */
    font-family: Bebas Neue;
    font-size: 96px;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
}

.home_section1-left-header-top {
    color: #F96706;
}

.home_section1-left-header-btm {
    color: #3F4448;
}

.text-head {
    font-weight: 700 !important;
    color: #F96706 !important;
}

.home_section1-left-text {
    color: #3F4448;
    max-width: 700px;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.numberWrap {
    background: #3F4448;
    color: #FFFEFD;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    font-family: Bebas Neue;
}

.home_skill-paragraph {
    color: #3F4448;
    max-width: 500px;
    width: 100%;
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


/* Mobile Responsiveness */

@media only screen and (max-width: 500px) {

    .home_section1,
    .home_section3 {
        flex-direction: column;
    }

    .home_section1-left {
        height: -webkit-fill-available;
    }

    .home_section1-left-top {
        height: 100%;
        gap: 60px;
        justify-content: space-between;
        text-align: center;
    }

    .home_section1-left-header,
    .home_section3-left-header-top {
        font-size: 24px;
    }

    .home_section1-left-text {
        font-size: 12px;
    }

    .cta {
        position: absolute;
        z-index: 3;
        bottom: 0;
        display: none;
    }

    .landing_image {
        /* height: 55vh !important; */
        /* margin: 20px; */
        width: 90vw;
        margin-bottom: -20px;
    }


}