.prj_land_wrap {
    /* padding-left: 20px;
    padding-right: 20px; */
    padding-bottom: 20px;
}

.prj_land_ul {
    width: 100vw;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0 20px;
    padding-top: 20px;
}

.prj_coverImg {
    border-radius: 10px;
    border: 1px solid #e0e0e0ee;
}

.prj_title {
    color: #666;
    margin-top: 5px;
}

@media only screen and (max-width: 500px) {

    /* For Mobile: */

    .prj_land_ul {
        display: flex;
        flex-direction: column;
        width: 90vw;
    }

    .prj_land_li {
        max-width: 100vw;
        width: 100%;
    }


}

@media only screen and (min-width: 1000px) {

    /* For desktop: */
    .prj_land_ul {
        display: flex;
        flex-direction: row;
        width: 100vw;
    }

    .prj_land_li {
        max-width: 31vw;
        min-width: 100px;
        width: 100%;
    }

}


@media only screen and (min-width: 767px) {

    /* For Tablet: */
    .prj_land_li {
        /* max-width: 690px; */
        width: 100%;
    }

    .prj_land_ul {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

}

.prj_coverImg:hover {
    box-shadow: 7px 8px 21px -2px rgba(46, 43, 42, 0.10);
}