.project_banner {
    width: 100vw;
}

.prototype_container {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 50px;
    padding-top: 0 !important;
}

.prototype_text {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 72px;
    line-height: normal;

}