.navbar_wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #FFFEFD;
    font-family: 'Bebas Neue', sans-serif;
    z-index: 2;
    /* margin-bottom: 20px; */
    padding: 15px 25px;
    position: sticky;
    top: 0;
}

.navbar_logo {
    padding: 0;
    height: min-content;
}

a {
    color: #4B4541;
}

.navbar_navigation_wrap {}

.navbar_navigation_itm {}

.navbar_navigation_wrap {
    padding: 0;
    display: flex;
    gap: 20px;
    margin: 0;
}

.navbar_navigation_itm {
    color: #4B4541;
    font-size: 20px;
    list-style-type: none;
    padding: 0;
    display: flex;
}

a {
    text-decoration: none;
}

.nav_active {
    color: #F96706 !important;
}

.footer {
    background: #fffefd;
    color: #4b4541;
    font-size: 11px;
    padding: 15px 15px;
    font-family: Roboto Mono;
    position: absolute;
    /* bottom: 0; */
    width: -webkit-fill-available;
    margin-top: 20px;
    text-align: center;
}