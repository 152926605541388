.prmry_head {
    font-size: 48px;
    font-weight: 600;
    font-family: 'Bebas Neue', sans-serif;
    /* font-family: 'Hanken Grotesk', sans-serif;
font-family: 'Nunito', sans-serif; */
}

.scndry_head {
    font-size: 24px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    color: #4B4541;

    margin-top: 20px;
    margin-bottom: 20px;
}

.project_wrap {
    height: fit-content;
}

.head_logo_wrap {
    display: flex;
    gap: 10px;
}

.kaizen_txt {
    width: 100%;
}

.kaizen_banner {
    width: 100%;
}

.project_secction {
    padding: 20px;
    height: fit-content;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    text-align: center;
}

.project_content_wrap {
    /* height: 150px; */
}

.kaizen_banner {
    width: 50%;
}

.banner_section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project_content_gamification {
    padding: 0;
    margin: 0;
}

.project_content_wireframes_wrap {
    padding-top: 20px;
}

.gf_bd_li {
    padding: 0 !important;
    margin: 0;
    list-style: none;
}

.gf_bd_img {
    width: 100%;
}

.persona {
    width: 100% !important;
    margin-left: 3%;
    overflow: hidden;
}

.btn {
    padding: 20px 60px;
    border-radius: 0;
    border: .5px solid #4B4541;
    background: none;
    font-family: Roboto Mono;
    text-transform: uppercase;
    font-weight: 500;
    color: #4B4541;
}

.prj_s_hd {
    color: #4B4541;
    margin-bottom: -20px;
}

.cnt_crd {
    /* height: 250px;
    display: flex;
    align-items: center; */
}

.project_content_txt {
    font-size: 13px;
    width: 70%;
    display: flex;
    align-items: center;
    /* width: 100%; */
    text-align: center;
    justify-content: center;
    margin: 0 auto;
    color: #4B4541;
    font-weight: 400;
    max-width: 600px;
}

.thrd_hd {
    font-weight: 600;
    margin-top: 60px;
    margin-bottom: 30px;
    color: #b0adab;
}