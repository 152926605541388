.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.text-heading-orange {
  font-size: 96px;
  font-family: 'Bebas Neue';
  color: #F96706;
}

.text-heading-black {
  font-size: 96px;
  font-family: 'Bebas Neue';
  color: #363432;
}

.cta-text {
  color: #363432;

  font-family: 'Roboto Mono';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: none;
  border-radius: 65px;
  border: 1px solid #363432;
  padding: 10px 25px;
  text-transform: uppercase;
}

.cta-icon {
  background: none;
  border-radius: 65px;
  background: #363432;
  border: none;
  color: #ffffff;
  padding: 15px;
  display: flex;
  align-items: center;
  width: fit-content;
}