.home_wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* position: relative; */
}

.home_welcome_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 84vh;
}

.home_welcome_head {
    font-size: 108px !important;
    color: #F96706;
    /* font-style: italic; */
    /* -webkit-text-stroke: .1px; */
    /* text-shadow: 1px 1px #F96706; */
    /* -webkit-text-stroke: .1px #F96706; */
}

.home_welcome_img_wrap {}

.home_welcome_img {
    height: 150px;
    width: 150px;
    border-radius: 150px;
    object-fit: cover;
    margin-top: -300px;
}

.home_welcome_img_bg {
    /* background: url(../img/texture/noise.jpg), lightgray 50% / cover no-repeat; */
    opacity: 0.25;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
}

.home_welcome_bio {
    width: 250px;
    text-align: center;
    margin-top: 20px;
    font-size: 12px;
    color: #3B3836;
    font-weight: 500;
    font-family: Roboto Mono;
}

.home_elevatorPitch_wrap {
    /* margin-bottom: 40px;
    padding-bottom: 20px; */
    /* height: 300px; */
}

.home_elevatorPitch {
    width: 100%;
    aspect-ratio: 4/2.25;
    /* border-radius: 20px; */
    object-fit: cover;
    height: 320px;
    border-radius: 30px;
    border: 1px solid #B2B2B2;
}

.prj_land_wrap {
    margin: 0;
    height: fit-content;
    min-height: 90vh;
    padding-bottom: 60px;
}

.prj_land_ul {
    margin: 0;
    padding: 0;
    list-style: none !important;
}

.prj_land_li {
    margin: 0;
    padding: 0;
    list-style: none !important;
}

.about_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 64vh;
    width: 100%;
    text-align: center;
}

.about_wrap {
    font-family: Montserrat;
    /* text-transform: uppercase; */
    font-size: 20px;
    font-weight: 600;
    padding-top: 150px;
}

.about_txt {
    margin-bottom: 50px;
    color: #4B4541;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.about_btn {
    padding: 15px 30px;
    border: 2px solid #4B4541;
    background: none;
    font-family: Roboto Mono;
    text-transform: uppercase;
    font-weight: 500;
    color: #4B4541;
    border-radius: 30px;
    cursor: pointer;
    min-width: 300px;
}

.about_btn_wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
}